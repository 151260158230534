import './App.css';
import Header from './components/Header'
import Footer from './components/Footer'
import Roicalc from './components/pages/Roicalc'
import Container from '@material-ui/core/Container';
import { Switch, Route } from 'react-router-dom';

import TwoPercentRule from './components/blog/TwoPercentRule'
import LowerMortgagePayments from './components/blog/LowerMortgagePayments'
import InvestingInOhio from './components/blog/InvestingInOhio'
import ScrollToTop from './components/ScrollToTop';
import PmtCalcPage from './components/pages/PmtCalcPage';
import CommissionCalcPage from './components/pages/CommissionCalcPage';
import Home from './components/pages/Home';

function App() {
  return (
    <div className="App">
      <Header />
      <Container maxWidth="md">
        <ScrollToTop>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/roi-calculator' component={Roicalc}/>
            <Route path='/pmt-calculator' component={PmtCalcPage}/>
            <Route path='/commission-calculator' component={CommissionCalcPage}/>
            <Route path='/two-percent-rule' component={TwoPercentRule}/>
            <Route path='/lower-mortgage-payments' component={LowerMortgagePayments}/>
            <Route path='/real-estate-investing-in-Ohio' component={InvestingInOhio} />
          </Switch>
        </ScrollToTop>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
