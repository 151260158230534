import React from 'react'
import '../styles/blog.css'
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Grid from '@material-ui/core/Grid';
import RelatedContent from '../RelatedContent';
import SocialsShare from '../SocialsShare'

export default function TwoPercentRule() {
    const path = 'https://houseStatistics.com/lower-mortgage-payments'
    
    return (
        <div>
            <Helmet>
                <title>How to get a lower mortgage payment when buying a house</title>
                <meta name="title" content="How to get a lower mortgage payment when buying a house" />
                <meta name="description" content="Understanding what influences your mortgage payment is important when entering any property investment.  If you are thinking of getting a property loan, prioritizing a low monthly mortgage payment may be important to you.  This article will focus on the four best ways for you to lower your mortgage payment." />
                <script type="application/ld+json">{`
                     {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                          "@type": "Question",
                          "name": "How does the downpayment affect mortgage?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Increasing the downpayment on a property has the benefit of reducing the principal that the home loan will apply to.  This simply means that the more you put down, the less you have to pay per month to pay off the property."
                          }
                        }, {
                          "@type": "Question",
                          "name": "How does pmi affect mortgage?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Pmi is an insurance that is added on top of a conventional mortgage loan if the downpayment on a property is lower than 20% of the homes purchase price.  In most cases, avoiding pmi at all costs is the right answer.  The problem with paying pmi is that it's a payment that does not reduce the loan principal or interest."
                          }
                        }, {
                          "@type": "Question",
                          "name": "How does credit score affect mortgage?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Having a good credit score is key to getting a loan with a lower interest rate.  According to myFico, a person with a 760-850 credit score can expect to receive an APR of 2.656% on a loan.  In contrast, a person with a 620-639 credit score can expect to receive an APR of 4.245%."
                          }
                        },
                                       {
                          "@type": "Question",
                          "name": "How loan type affect mortgage?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Choosing the best term for your mortgage loan brings up the age old debate of the 30 year loan vs the 15 year loan.  By definition, by having a larger loan term, you will have lower monthly payments.  However there is a tradeoff.  By extending the term of the loan, you will end up paying more interest."
                          }
                        }]
                      }
                `}</script>
            </Helmet>
            <article className="article-blog">
                <h1 className="header-blog">How to get a lower mortgage payment when buying a house</h1>
                <hr></hr>
                <div className="social-blog">
                    <SocialsShare 
                        path={path}
                    />
                </div>
                <img className="img-blog" src="https://images.pexels.com/photos/2079234/pexels-photo-2079234.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="header for lower mortgage article"/>

                    <Grid container spacing={2} className="grid">
                        <Grid item xl={8} lg={8} md={8} sm={8}>
                            <article className="article-body-blog">
                                <p className="paragraph-blog">Understanding what influences your mortgage payment is important when entering any property investment.  If you are thinking of getting a property loan, prioritizing a low monthly mortgage payment may be important to you.  This article will focus on the four best ways for you to lower your mortgage payment.</p>
                                <h2 className="subheader-blog">1. Make a larger downpayment</h2>
                                <p className="paragraph-blog">
                                    The initial downpayment on a property will play a large part in the mortgage you will pay.<br></br><br></br> 

                                    Increasing the downpayment on a property has the benefit of reducing the principal that the home loan will apply to.  This simply means that the more you put down, the less you have to pay per month to pay off the property.<br></br><br></br> 

                                    In addition, you will actually end up paying less interest over the term of the loan because the loan acted on a lower principal.  You can see this by trying out different loan amounts in the <Link to="/pmt-calculator">Mortgage Payment Calculator</Link> over the same period and interest rate.
                                </p>
                            </article>
                            <article className="article-body-blog">
                                <h2 className="subheader-blog">2. Avoid private mortgage insurance (pmi)</h2>
                                <p className="paragraph-blog">
                                    Avoiding private mortgage insurance is important in order to lower your mortgage payment.<br></br><br></br> 
                                    
                                    Pmi is an insurance that is added on top of a conventional mortgage loan if the downpayment on a property is lower than 20% of the homes purchase price.  Pmi rates generally vary between 0.5-2%.<br></br><br></br>
                                    
                                    In most cases, avoiding pmi at all costs is the right answer.  The problem with paying pmi is that it's a payment that does not reduce the loan principal or interest.  It's like a penalty for not having a 20% downpayment<br></br><br></br> 
                                    
                                    If you want to prioritize having a low mortgage, make sure to put at least 20% down to avoid pmi.
                                </p> 
                            </article>
                            <article className="article-body-blog">
                                <h2 className="subheader-blog">3. Improve your credit score</h2>
                                <p className="paragraph-blog">
                                    Having a good credit score is key to getting a loan with a lower interest rate.<br></br><br></br> 
                                    
                                    According to <a href="https://www.myfico.com/loan-center/home-mortgage-rate-comparison/default.aspx">myFico</a>, a person with a 760-850 credit score can expect to receive an APR of 2.656% on a loan.  In contrast, a person with a 620-639 credit score can expect to receive an APR of 4.245%.<br></br><br></br>

                                    Over the term of a loan, there is a large difference in the interest paid at 2.656% APR compared to at 4.245% APR.<br></br><br></br>
                                    
                                    For a 30 year $100,000 loan, the difference in total interest paid over the course of the loan is almost $32,000.<br></br><br></br>
                                      
                                    Improving your credit score to earn a lower APR loan is a good way to pay less interest and have a lower mortgage payment.
                                </p>
                            </article>
                            <article className="article-body-blog">
                                <h2 className="subheader-blog">4. Get a longer term on your mortgage loan</h2>
                                <p className="paragraph-blog">
                                    Choosing the best term for your mortgage loan brings up the age old debate of the 30 year loan vs the 15 year loan.  By definition, by having a larger loan term, you will have lower monthly payments.<br></br><br></br>
                                    
                                    However there is a tradeoff.  By extending the term of the loan, you will end up paying more interest.<br></br><br></br>

                                    One additional benefit of longer loan terms can bee seen in the return on investment (roi).  If you look at this <Link to="/roi-calculator">return on investment (roi) calculator</Link>, you can see that a 15 year loan generally will result in lower a lower roi when compared to a 30 year loan.<br></br><br></br>
                                      
                                    This why many real estate investors choose to get longer terms on a loan.<br></br><br></br>
                                    
                                    If you want to pay smaller chunks over the course of a longer period, getting a longer term on you loan is the way to go.
                                </p>
                            </article>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4}>
                            <RelatedContent />
                        </Grid>
                    </Grid>
                    
                    
            </article>
        </div>
    )
}
