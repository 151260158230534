import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import Chart from 'react-apexcharts'

export default class DonutChart extends Component {
    constructor(props){
        super(props)
        this.state = {
                options: {
                    labels: props.labels,
                    title: {
                        text: props.title,
                    },
                    plotOptions: {
                        pie: {
                          expandOnClick: false,
                          donut: {
                            labels: {
                              show: true,
                              value: {
                                fontSize: "15px",
                                fontWeight: 600
                              },
                              total: {
                                show: true,
                                showAlways: true,
                                fontSize: "10px",
                                label: props.total
                              }
                            }
                          }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                },
                series: props.series,
            }
        }
        componentDidUpdate(prevProps, prevState) {
            if (prevProps !== this.props) {
                this.setState({
                    options: {
                        labels: this.props.labels,
                        title: {
                            text: this.props.title
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    total: {
                                        label: this.props.total
                                    }
                                }
                            }
                        }
                    },
                    series: this.props.series,
                })
            }
        }
    
    render() {
        return (
            <div>
                <Card style={{marginTop: '1em', height: '1500%'}}>
                    <CardContent>
                        <Chart
                            type="donut" 
                            options={this.state.options}
                            series={this.state.series}
                        />
                    </CardContent>
                </Card>
            </div>
        )
    }
}
