import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
// import RoiImg from '../../images/roi-calculator'


export default class ImgCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            header: props.header,
            subheadder: props.subheadder,
            to: props.to,
            img: props.img
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({
                header: this.props.header,
                subheadder: this.props.subheadder,
                to: this.props.to,
                img: this.props.img
            })
        }
    }
    render() {
        return (
            <div style={{marginTop: '1em'}}>
                <Link to={this.state.to} style={{textDecoration: 'none'}}>
                    <Card>
                        <CardMedia>
                        <CardMedia
                            component="img"
                            alt={this.state.header}
                            height="250"
                            image={this.state.img}
                            title={this.state.header}
                        />
                        </CardMedia>
                        <h2>{this.state.header}</h2>
                        <p>{this.state.subheadder}</p>
                    </Card>
                </Link>
            </div>
        )
    }
}
