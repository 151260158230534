import React, { useEffect } from 'react';
import '../styles/blog.css'
import Zillow from '../../images/zillow.png'
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Grid from '@material-ui/core/Grid';
import RelatedContent from '../RelatedContent';
import SocialsShare from '../SocialsShare'
import TwoPercentCalc from '../TwoPercentCalc';

export default function TwoPercentRule() {
    const path = 'https://houseStatistics.com/two-percent-rule'
    useEffect(() => {
        //Preload images
        const imgList = [Zillow]
        imgList.forEach((image) => {
            new Image().src = image
        })
    })
    return (
        <div>
            <Helmet>
                <title>How to invest in real estate using the 2% rule</title>
                <meta name="title" content="How to invest in real estate using the 2% rule" data-react-helmet="true" />
                <meta name="description" content="The 2% rule is used in order to relate the value of the property to the rental value of the property.  Specifically, the 2% rule in real estate states that <strong>the monthly rent for a property should be at least 2% of the total cost of the property." data-react-helmet="true"/>
            </Helmet>
            <article className="article-blog">
                <h1 className="header-blog">How to invest in real estate using the 2% Rule</h1>
                <hr></hr>
                <div className="social-blog">
                    <SocialsShare 
                        path={path}
                    />
                </div>
                <img className="img-blog" src="https://images.pexels.com/photos/669610/pexels-photo-669610.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="header for two-percent-rule article"/>

                    <Grid container spacing={2} className="grid">
                        <Grid item xl={8} lg={8} md={8} sm={8}>
                            <article className="article-body-blog">
                                <h2 className="subheader-blog">What is the 2% rule in real estate?</h2>
                                <p className="paragraph-blog">
                                    When looking into real estate investing, you will come across several rules that people use to guide their investment decisions.  The 2% rule is one of those rules used to relate the value of a property to how much rent the property should generate.<br /><br />
                                    
                                    Specifically, the 2% rule in real estate states that <strong>the monthly rent for a property should be at least 2% of the total cost of the property.</strong><br /><br />
                                    
                                    To better understand this, lets look an example.<br /><br />
                                    
                                    Lets say a property is valued at $100,000. Using the rule, the monthly rent that the property should generate is $2000 or (0.02 * $100,000).  Its really that simple.<br /><br />

                                    Use the calculator below to calculate monthly rent using the 2% rule.<br /><br />
                                    <div className="calculator">
                                        <TwoPercentCalc />
                                    </div><br />

                                    However, it's important to realize that there are many other factors that should be considered before judging a property by this rule.  Properties that do not meet the 2% rule can still be great investments.<br /><br />
                                    
                                    The purpose of this rule is just to provide a quick estimation of a "best case" rental value for the property.
                                </p> 
                            </article>
                            <article className="article-body-blog">
                                <h2 className="subheader-blog">Is the 2% rule realistic?</h2>
                                <p className="paragraph-blog">If you do a quick search through Zillow, you may notice that finding a property that meets the 2% rule is very difficult.<br /><br />
                            
                                Looking at properties in <a href="https://www.zillow.com/oh/houses/?searchQueryState=%7B%22pagination%22%3A%7B%7D%2C%22usersSearchTerm%22%3A%22ohio%22%2C%22mapBounds%22%3A%7B%22west%22%3A-86.5748911484375%2C%22east%22%3A-78.7636118515625%2C%22south%22%3A37.98015645171111%2C%22north%22%3A42.724026002768305%7D%2C%22regionSelection%22%3A%5B%7B%22regionId%22%3A44%2C%22regionType%22%3A2%7D%5D%2C%22isMapVisible%22%3Atrue%2C%22filterState%22%3A%7B%22ah%22%3A%7B%22value%22%3Atrue%7D%2C%22con%22%3A%7B%22value%22%3Afalse%7D%2C%22mf%22%3A%7B%22value%22%3Afalse%7D%2C%22manu%22%3A%7B%22value%22%3Afalse%7D%2C%22land%22%3A%7B%22value%22%3Afalse%7D%2C%22tow%22%3A%7B%22value%22%3Afalse%7D%2C%22apa%22%3A%7B%22value%22%3Afalse%7D%2C%22apco%22%3A%7B%22value%22%3Afalse%7D%7D%2C%22isListVisible%22%3Atrue%2C%22mapZoom%22%3A7%7D">Ohio</a>, you can quickly see that most of the properties do not have rental values even close to meeting the 2% rule.<br /><br />
                                
                                Most of the properties only get close to the 1% rule (essentially the same as thing as the 2% rule but the monthly rent is 1% of the home value), but none really get close to 2%.<br /><br />
                            
                                <img src={Zillow} className="img-blog" alt="Zillow property for sale in ohio that does not meet the 2% rule"/><br /><br />

                                To find properties that meet this rule, you may find better luck looking in states that have <a href="https://learn.roofstock.com/blog/cheapest-states-to-buy-a-house">lower median home values</a> such as Oklahoma, West Virginia, or Mississippi.<br /><br />  Since the cost of the property is lower, it's easier to find homes that meet the 2% rule when compared to locations that have high property values, such as california.<br /><br />

                                The most important thing to realize is that even if a property does not meet the 2% rule, it can still be a good investment.<br /><br />
                                
                                There are other factors such as HOA fees, insurance, and other costs need to be taken into consideration.  Using these factors to <Link to="/roi-calculator">calculate return on investment (ROI)</Link> may be a better metric to determine if a property is a good investment when compared to just looking that the rent you can get out of a property.<br /><br />
                            
                                It does not matter if you rental property meets the 2% rule if all of the rent is used to fix the property and pay monthly fees.
                                </p>
                            </article>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4}>
                            <RelatedContent />
                        </Grid>
                    </Grid>
                    
                    
            </article>
        </div>
    )
}
