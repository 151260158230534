import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import Grid from '@material-ui/core/Grid';
import RelatedContent from '../RelatedContent';
import Card from '@material-ui/core/Card';
import SocialsShare from '../SocialsShare'
import ImgCard from '../ImgCard';
import RoiImg from '../../images/roi-calculator.png'
import CommissionImg from '../../images/commission-calculator.png'
import MortgageImg from '../../images/mortgage-calculator.png'

export default function Home() {
    const path = 'https://housestatistics.com/'

    useEffect(() => {
        //Preload images
        const imgList = [RoiImg, CommissionImg, MortgageImg]
        imgList.forEach((image) => {
            new Image().src = image
        })
    })
    return (
        <div>
            <Helmet>
                <title>HouseStatistics</title>
                <meta name="title" content="HouseStatistics" />
                <meta name="description" content="Easy to use real estate tools for investors and agents.  We provide free resources to make real estate investing easy." />
            </Helmet>
            <Card style={{padding: '3em 0', color: 'white', margin: '1em 0', backgroundColor: '#f50057'}}>
                <h1 style={{fontSize:'3em'}}>HouseStatistics</h1>
                <p>Easy to use real estate tools for investors and agents.</p>
                <p>We provide free resources to <strong>make real estate investing easy</strong></p>
                </Card>
                <SocialsShare 
                    path={path}
                />
            <Grid container style={{marginBottom: '1em'}} spacing={2}>
                <Grid item xl={8} lg={8} md={8} sm={8} style={{paddingLeft: '2em', paddingRight: '2em'}}>
                    <ImgCard 
                        header="roi calculator"
                        to="/roi-calculator"
                        img={RoiImg}
                    />
                    <ImgCard 
                        header="Commission calculator"
                        to="/commission-calculator"
                        img={CommissionImg}
                    />
                    <ImgCard 
                        header="Mortgage payment calculator"
                        to="/pmt-calculator"
                        img={MortgageImg}
                    />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} style={{marginTop: '1em', textAlign: 'left'}}>
                    <RelatedContent />
                </Grid>
            </Grid>
        </div>
    )
}
