import React from 'react'
import '../styles/blog.css'
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Grid from '@material-ui/core/Grid';
import RelatedContent from '../RelatedContent';
import SocialsShare from '../SocialsShare'

export default function TwoPercentRule() {
    const path = 'https://houseStatistics.com/real-estate-investing-in-Ohio'
    return (
        <div>
            <Helmet>
                <title>Real estate investing in Ohio</title>
                <meta name="title" content="Real estate investing in Ohio" />
                <meta name="description" content="" />
                <script type="application/ld+json">{`
                     {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                          "@type": "Question",
                          "name": "How does the downpayment affect mortgage?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Increasing the downpayment on a property has the benefit of reducing the principal that the home loan will apply to.  This simply means that the more you put down, the less you have to pay per month to pay off the property."
                          }
                        }, {
                          "@type": "Question",
                          "name": "How does pmi affect mortgage?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Pmi is an insurance that is added on top of a conventional mortgage loan if the downpayment on a property is lower than 20% of the homes purchase price.  In most cases, avoiding pmi at all costs is the right answer.  The problem with paying pmi is that it's a payment that does not reduce the loan principal or interest."
                          }
                        }, {
                          "@type": "Question",
                          "name": "How does credit score affect mortgage?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Having a good credit score is key to getting a loan with a lower interest rate.  According to myFico, a person with a 760-850 credit score can expect to receive an APR of 2.656% on a loan.  In contrast, a person with a 620-639 credit score can expect to receive an APR of 4.245%."
                          }
                        },
                                       {
                          "@type": "Question",
                          "name": "How loan type affect mortgage?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Choosing the best term for your mortgage loan brings up the age old debate of the 30 year loan vs the 15 year loan.  By definition, by having a larger loan term, you will have lower monthly payments.  However there is a tradeoff.  By extending the term of the loan, you will end up paying more interest."
                          }
                        }]
                      }
                `}</script>
            </Helmet>
            <article className="article-blog">
                <h1 className="header-blog">Real estate investing in Ohio</h1>
                <hr></hr>
                <div className="social-blog">
                    <SocialsShare 
                        path={path}
                    />
                </div>
                <img className="img-blog" src="https://siteselection.com/issues/2020/may/images/Lead_70.jpg" alt="ohio skyline image"/>

                    <Grid container spacing={2} className="grid">
                        <Grid item xl={8} lg={8} md={8} sm={8}>
                            <article className="article-body-blog">
                                {/* <h2 className="subheader-blog">Introduction</h2> */}
                                <p className="paragraph-blog">
                                    In the past year, Ohio properties have seen a historic surge in value in the market.
                                    
                                    According to <a href="https://www.neighborhoodscout.com/oh/real-estate">neighborhoodscout</a>, properties in ohio have an average annual appreciation rate of 9.57%.  For reference, the national appreciation rate is about 4%.<br></br><br></br>
                                    
                                    Many investors are now rushing to buy properties in Ohio, causing bidding wars to inflate prices as soon as new properties reach the market.<br></br><br></br>
                                    
                                    In this article, we will go over what you should know before investing in Ohio real estate.
                                </p>

                                <h2 className="subheader-blog">Should I invest in Ohio real estate?</h2>
                                <p className="paragraph-blog">
                                    With property values that have grown 10% in the past year, investing in Ohio seems very appealing.  In addition, interest rates are at historic lows, driving demand for every new property that hits the market.  For many investors, Ohio is a popular investment spot because the median home price of about $168,000 is much lower than the national median.  This makes investing in Ohio more feasible compared to in other states with a higher average property price.
                                    
                                    However, this recent market boom is making it difficult to get properties at reasonable values.<br></br><br></br>

                                    Many buyers are offering prices over market value, causing bidding wars that drive up property values.  Also, Ohio is notorious for having one of the largest property tax rates at about <a href="https://smartasset.com/taxes/ohio-property-tax-calculator">1.48%</a>.<br></br><br></br>

                                    If you do choose to invest in Ohio, make sure to do your research.  While there are lots of opportunities in this market, do not get caught over-paying.  Ohio offers 
                                </p>
                                <h2 className="subheader-blog">Investing in Columbus</h2>
                                <p className="paragraph-blog">
                                    Columbus is the largest city by population in the state of Ohio.  It is the capital city and is home to almost 1.7 million residents.
                                </p>
                            </article>
                        </Grid>
                        <Grid item xl={4} lg={4} md={4} sm={4}>
                            <RelatedContent />
                        </Grid>
                    </Grid>
                    
                    
            </article>
        </div>
    )
}
