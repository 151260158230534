import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import InfoCard from './InfoCard'
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { CardContent, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DonutChart from './DonutChart'

export default class PmtCalculator extends Component {
    constructor(){
        super();
        this.state = {
            path: 'https://houseStatistics/blog/two-percent-rule',
            loanValue: 100000,
            years: 30,
            interestRate: 2.3,
            
            pmtCard: {
                unit: '',
                value: '',
                description: ''
            },
            interestCard: {
                unit: '',
                value: '',
                description: ''
            },
            Donut: {
                series: [],
                labels: []
            }
        }
    }
    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    componentDidMount() {
        this.calculatePmt()
    }

    calculatePmt() {
        function round(value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        }
        let principal = parseFloat(this.state.loanValue)
        let interestRate = parseFloat(this.state.interestRate) / 100
        let years = parseFloat(this.state.years)
        let PMT = round((principal*(interestRate / 12))/(1 - Math.pow((1 + (interestRate / 12)), (-12 * years))),2)
        let totalInterest = round((PMT*12*years)-principal,2)
        this.setState({
            pmtCard: {
                unit: '$',
                value: PMT,
                description: 'Monthly payment(PMT) for loan'
            },
            interestCard: {
                unit: '$',
                value: totalInterest,
                description: `Total interest owed over ${years} years`
            },
            Donut: {
                series: [round(principal, 0), round(totalInterest, 0)],
                labels: ['Principal', 'Interest']
            }
        })
    }

    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                        <Paper>
                            <Card style={{marginTop: '1em'}}>
                                <CardContent>
                                    <h2>Payment(PMT) calculator</h2>
                                    <FormControl fullWidth variant="outlined" size="small" style={{marginTop: '1em'}}>
                                            <InputLabel htmlFor="loan-value">Loan Value</InputLabel>
                                            <OutlinedInput
                                                id="loan-value"
                                                type="number"
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                labelWidth={80}
                                                defaultValue={this.state.loanValue}
                                                onChange={this.handleChange('loanValue')}                               
                                            />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" size="small" style={{marginTop: '1em'}}>
                                            <InputLabel htmlFor="year-value">Years</InputLabel>
                                            <OutlinedInput
                                                id="year-value"
                                                type="number"
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                labelWidth={40}
                                                defaultValue={this.state.years}
                                                onChange={this.handleChange('years')}                               
                                            />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" size="small" style={{marginTop: '1em'}}>
                                            <InputLabel htmlFor="interest-value">Annual interest rate</InputLabel>
                                            <OutlinedInput
                                                id="interest-value"
                                                type="number"
                                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                labelWidth={140}
                                                defaultValue={this.state.interestRate}
                                                onChange={this.handleChange('interestRate')}                               
                                            />
                                    </FormControl>
                                    <Button fullWidth onClick={() => this.calculatePmt()} variant="contained" color="secondary" style={{marginTop: '1em'}}>Calculate</Button>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                        <InfoCard 
                            unit={this.state.pmtCard.unit}
                            value={this.state.pmtCard.value}
                            description={this.state.pmtCard.description}
                        />
                        <InfoCard 
                            unit={this.state.interestCard.unit}
                            value={this.state.interestCard.value}
                            description={this.state.interestCard.description}
                        />
                        <DonutChart 
                            options={{}}
                            series={this.state.Donut.series}
                            labels={this.state.Donut.labels}
                            title="Principal and interest breakdown"
                            total="Total payment"
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
