import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { CardContent } from '@material-ui/core';

export default class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            description: props.description,
            unit: props.unit
        }
      }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({
                value: this.props.value,
                description: this.props.description,
                unit: this.props.unit
            })
        }
    }
    render() {
        return (
            <div>
                <Card style={{marginTop: '1em', textAlign: 'left'}}>
                    <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        <strong>{this.state.unit}{this.state.value}</strong>
                    </Typography>
                    <Typography component="p" gutterBottom>
                        {this.state.description}
                    </Typography>
                    </CardContent>
                </Card>
            </div>
        )
    }
}
