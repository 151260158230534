import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import InfoCard from './InfoCard'
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { CardContent, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DonutChart from './DonutChart'

export default class CommissionCalc extends Component {
    constructor(){
        super();
        this.state = {
            path: 'https://houseStatistics/blog/two-percent-rule',
            propertyValue: 250000,
            commissionPercent: 3,
            agentCommission: 70,
            
            agentCard: {
                unit: '',
                value: '',
                description: ''
            },
            brokerCard: {
                unit: '',
                value: '',
                description: ''
            },
            Donut: {
                series: [],
                labels: []
            }
        }
    }

    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    componentDidMount() {
        this.calculateCommission()
    }

    calculateCommission() {
        function round(value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        }
        let propertyValue = parseFloat(this.state.propertyValue)
        let commissionRate = parseFloat(this.state.commissionPercent) / 100
        let totalCommission = propertyValue * commissionRate
        let agentCommissionPercent = parseFloat(this.state.agentCommission / 100)
        let brokerCommissionPercent = 1 - agentCommissionPercent
        let agentCommission = round(agentCommissionPercent*totalCommission, 2)
        let brokerCommission = round(brokerCommissionPercent*totalCommission, 2)
        this.setState({
            agentCard: {
                unit: '$',
                value: agentCommission,
                description: `${this.state.agentCommission}% Commission given to agent`
            },
            brokerCard: {
                unit: '$',
                value: brokerCommission,
                description: `${100-this.state.agentCommission}% Commission given to broker`
            },
            Donut: {
                series: [round(brokerCommission,0), round(agentCommission,0)],
                labels: ['Broker', 'Agent']
            }
        })
    }

    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                        <Paper>
                            <Card style={{marginTop: '1em'}}>
                                <CardContent>
                                    <h2>commission calculator</h2>
                                    <FormControl fullWidth variant="outlined" size="small" style={{marginTop: '1em'}}>
                                            <InputLabel htmlFor="property-value">Property Value</InputLabel>
                                            <OutlinedInput
                                                id="property-value"
                                                type="number"
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                labelWidth={105}
                                                defaultValue={this.state.propertyValue}
                                                onChange={this.handleChange('propertyValue')}                               
                                            />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" size="small" style={{marginTop: '1em'}}>
                                            <InputLabel htmlFor="commission-value">Commission Percent</InputLabel>
                                            <OutlinedInput
                                                id="commission-value"
                                                type="number"
                                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                labelWidth={150}
                                                defaultValue={this.state.commissionPercent}
                                                onChange={this.handleChange('commissionPercent')}                               
                                            />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" size="small" style={{marginTop: '1em'}}>
                                            <InputLabel htmlFor="agent-value">Percent of commission going to agent</InputLabel>
                                            <OutlinedInput
                                                id="agent-value"
                                                type="number"
                                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                                labelWidth={280}
                                                defaultValue={this.state.agentCommission}
                                                onChange={this.handleChange('agentCommission')}                               
                                            />
                                    </FormControl>

                                    <Button fullWidth onClick={() => this.calculateCommission()} variant="contained" color="secondary" style={{marginTop: '1em'}}>Calculate</Button>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                        <InfoCard 
                            unit={this.state.agentCard.unit}
                            value={this.state.agentCard.value}
                            description={this.state.agentCard.description}
                        />
                        <InfoCard 
                            unit={this.state.brokerCard.unit}
                            value={this.state.brokerCard.value}
                            description={this.state.brokerCard.description}
                        />
                        <DonutChart 
                            options={{}}
                            series={this.state.Donut.series}
                            labels={this.state.Donut.labels}
                            title="Agent vs Broker commission breakdown"
                            total="Total commission"
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}
