import PmtCalculator from '../PmtCalculator'
import {Helmet} from "react-helmet";
import SocialsShare from '../SocialsShare';
import '../styles/info.css'
import RelatedContent from '../RelatedContent';

export default function PmtCalcPage() {
    const path = 'https://houseStatistics.com/pmt-calculator'
    return (
        <div>
            <Helmet>
                <title>Mortgage payment calculator</title>
                <meta name="title" content="Mortgage payment calculator" />
                <meta name="description" content="Calculate monthly payment for any loans based on a constant payment and interest rate using this free loan analysis tool. Simply input the loan amount, number of years, and interest rate details and click the calculate button." />
            </Helmet>

            <h1 style={{color: "#39414D"}}>
                Mortgage payment calculator
            </h1>

            <p>Calculate monthly payment for loans based on a constant payment and interest rate</p>

            <SocialsShare 
                path={path}
            />

            <PmtCalculator />

            <div className="info-container">
                <hr></hr>
                <h2 className="info-header">An online calculator to calculate monthly payment (PMT) assuming constant payments and a fixed interest rate</h2>

                <section>
                    <h3 className="info-subheadder">How do you use this PMT calculator?</h3>
                    <p className="info-paragraph">Simply input the loan amount, number of years, and interest rate details and click the calculate button.  This calculator assumes a fixed payment and a fixed interest rate over the period of the loan.</p>
                </section>
                <section>
                    <h3 className="info-subheadder">What is the meaning of PMT in real estate?</h3>
                    <p className="info-paragraph">PMT, short for payment, refers to the periodic payments that are made in order to pay down a loan.  In addition to the principal (the base amount of the loan), an interest rate is added on-top of the loan.<br></br><br></br>
                    
                    This means that a portion of the PMT will pay down the principal the other part will pay down the interest.  Understanding this is especially important in real estate because of how large loans can be.<br></br><br></br>
                    
                    For example, while the monthly payment for a 30 year fixed interest loan may be lower when compared to the monthly payment of a 15 year fixed interest loan, the total amount of interest paid over the course of the 30 year loan will be much larger.  Understanding this tradeoff is important before jumping into any real estate investment<br></br><br></br>
                    </p>
                </section>

            <RelatedContent />

            </div>

        </div>
    )
}
