import React, { Component } from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    RedditShareButton,
    TwitterShareButton,
    TwitterIcon,
    RedditIcon,
    PinterestIcon,
    LinkedinIcon,
    FacebookIcon
  } from "react-share";

export default class SocialsShare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: props.value,
        }
      }
    render() {
        return (
            <div>
                <FacebookShareButton url={this.props.path}><FacebookIcon round={true} size={32}></FacebookIcon></FacebookShareButton>
                <TwitterShareButton url={this.props.path}><TwitterIcon round={true} size={32}></TwitterIcon></TwitterShareButton>
                <LinkedinShareButton url={this.props.path}><LinkedinIcon round={true} size={32}></LinkedinIcon></LinkedinShareButton>
                <RedditShareButton url={this.props.path}><RedditIcon round={true} size={32}></RedditIcon></RedditShareButton>
                <PinterestShareButton url={this.props.path}><PinterestIcon round={true} size={32}></PinterestIcon></PinterestShareButton>
            </div>
        )
    }
}
