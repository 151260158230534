import React from 'react'
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function RelatedContent() {
    return (
        <div>
            <Card>
                <CardContent>
                    <h2 style={{fontSize: '1em'}}>Related Content</h2>
                    <ol>
                        <li style={{marginTop: '0.5em'}}><Link to="/roi-calculator">Real Estate Investment ROI Calculator</Link></li>
                        <hr style={{marginTop: '1em', marginBottom: '1em'}} />
                        <li style={{marginTop: '0.5em'}}><Link to="/commission-calculator">Realtor commission calculator</Link></li>
                        <hr style={{marginTop: '1em', marginBottom: '1em'}} />
                        <li style={{marginTop: '0.5em'}}><Link to="/two-percent-rule">How to invest in real estate using the 2% rule</Link></li>
                        <hr style={{marginTop: '1em', marginBottom: '1em'}} />
                        <li style={{marginTop: '0.5em'}}><Link to="/pmt-calculator">Mortgage payment calculator</Link></li>
                        <hr style={{marginTop: '1em', marginBottom: '1em'}} />
                        <li style={{marginTop: '0.5em'}}><Link to="/lower-mortgage-payments">How to get a lower mortgage payment when buying a house</Link></li>
                        {/* <hr style={{marginTop: '1em', marginBottom: '1em'}} />
                        <li style={{marginTop: '0.5em'}}><Link to="/real-estate-investing-in-Ohio">Real estate investing in Ohio</Link></li> */}
                    </ol>
                </CardContent>
            </Card>
        </div>
    )
}
