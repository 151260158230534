import RelatedContent from './RelatedContent'

export default function Info() {
        return (
            <div style={{textAlign: 'left', marginTop: '3em', paddingBottom: '3em'}}>
                <hr></hr>
                <h2 style={{fontSize: '2em'}}>An online calculator for analyzing potential real estate investments</h2>
                <section>
                    <h3 style={{fontSize: '1.5em', marginTop: '2em'}}>What is return on investment (ROI)?</h3>
                    <p style={{fontSize: '1.2em', lineHeight: '1.5em'}}>ROI is a measure of how <strong>profitable</strong> a particular investment may be.  ROI is calculated by dividing the potential returns of an investment by the cost of the investment itself.  The purpose of this application is to simplify the process of calculating ROI for real estate investment properties.  Having this information can help you make more informed decisions when entering into a particular investment</p>
                </section>
                
                <section>
                    <h3 style={{fontSize: '1.5em', marginTop: '2em'}}>How to use a ROI calculator?</h3>
                    <p style={{fontSize: '1.2em', lineHeight: '1.5em'}}>Simply input key information about the property and the return on investment percent will be calculated.</p>
                    <p style={{fontSize: '1.2em', lineHeight: '1.5em'}}>This calculator takes into account several variables used to make a more accurate calculation of the return on investment.</p>
                    <ul style={{fontSize: '1.2em', lineHeight: '1.5em'}}>
                        <li><strong>Property value</strong></li>
                        <li><strong>Downpayment percent</strong></li>
                        <li><strong>Loan interest rate</strong></li>
                        <li><strong>Rent per month</strong></li>
                        <li><strong>Property tax</strong></li>
                        <li><strong>HOA</strong></li>
                        <li><strong>Home insurance</strong></li>
                        <li><strong>Renovation + closing costs</strong></li>
                    </ul>
                    <p style={{fontSize: '1.2em', lineHeight: '1.5em'}}>The larger the ROI, the more profitable the investment property might be.</p>
                </section>
                <section>
                    <h3 style={{fontSize: '1.5em', marginTop: '2em'}}>What is a good ROI?</h3>
                    <p style={{fontSize: '1.2em', lineHeight: '1.5em'}}>The definition of a "good" ROI can be different from investor to investor.  For reference, the S&P500 index has historically provided a <a href="https://www.businessinsider.com/personal-finance/average-stock-market-return">8-9% ROI yearly</a>.  If 8-9% ROI is assumed to be the average ROI for an investment, anything larger can be considered a good investment.</p>
                    <p style={{fontSize: '1.2em', lineHeight: '1.5em'}}>However, this is not an exact science.  While something may have the potential for larger returns, in most cases, it can be a more risky investment.  While investing in bonds may have a ROI less than 5%, it is generally considered a very safe investment.</p>
                    <p style={{fontSize: '1.2em', lineHeight: '1.5em'}}>Identifying your risk and what you value as a good ROI can help inform decisions on what to invest in.</p>
                </section>
                <RelatedContent />
            </div>
        )
}
