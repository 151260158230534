import React, { Component } from 'react'
import {AppBar, Toolbar, Typography, Button} from '@material-ui/core'
import { Link } from 'react-router-dom';

export default class Header extends Component {
    render() {
        return (
            <div>
                <AppBar position="static" style={{backgroundColor: "#F8F8F8", color: "#39414D"}}>
                    <Toolbar>
                        <Typography style={{flexGrow: 1, textAlign: 'left'}} variant="h6" edge="start">
                        <Link to="/" style={{textDecoration: 'none', color: "#39414D"}}>HouseStatistics</Link>
                        </Typography>
                        <Button href="https://chrome.google.com/webstore/detail/real-estate-roi-calculato/jcdocbolcpfcdbbjfnfokpidfoepbnkb" color="inherit">Chrome Extension</Button>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}
