import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div style={{backgroundColor: '#39414D', color: 'white', padding: '2em'}}>
                <h2>HouseStatistics</h2>
                <a href="https://www.privacypolicies.com/live/6c667763-ca87-4cde-a6e6-e5a4c237b729" style={{color: 'white'}}>Privacy policy</a>
                <p>Email HouseStatisticsWeb@gmail.com for feature requests or bugs</p>
            </div>
        )
    }
}
