import React from 'react'
import CommissionCalc from '../CommissionCalc'
import {Helmet} from "react-helmet";
import SocialsShare from '../SocialsShare';
import '../styles/info.css'
import RelatedContent from '../RelatedContent';

export default function CommissionCalcPage() {
    const path = 'https://houseStatistics.com/commission-calculator'
    return (
        <div>
            <Helmet>
                <title>Real estate agent commission calculator</title>
                <meta name="title" content="Realtor commission calculator" />
                <meta name="description" content="As an agent working under a brokerage, whatever commission that the agent gets from the sellers is split between the agent and the brokerage.  Use this calculator to estimate the commission an agent will get based on the commission percent and the split percent between the agent and the brokerage"  />

                <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                          "@type": "Question",
                          "name": "What is a brokerage split in real estate?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "As an agent working under a brokerage, whatever commission that the agent gets from the sellers is split between the agent and the brokerage.  Typical broker commission splits are 50/50, 60/40, or 70/30.  For example,in a typical 70/30 split, the agent gets to take home 70% of the commission while the brokerage gets the remaining 30%."
                          }
                        }, {
                          "@type": "Question",
                          "name": "How to calculate a 70/30 commission split?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "For example, lets say an agent sold a property valued at $250,000 and expects to receive a commission of 3%.

                This means that the total commission received in this transaction is $7,500 or (0.03 * 250,000).

                Now, lets say that the brokerage that the agent works for expects a 70/30 split of the commission.  This means that of the total $7,500 of commission, the agent gets to keep 70% and the brokerage gets the remaining %30.

                Based on this, the agent takes home $5,250 or (0.7 * 7,500) and the brokerage get $2,250 or (0.3 * 7,500).

                Using this example for reference, you can also easily calculate other commission splits.  For calculating 50/50 or 60/40 splits, simply take the total commission and multiply but the relative percents.  For example in a 50/50 split with the example above, the agent would take home $3,750 or (0.5*7,500) and the broker would also get $3,750 or (0.5 * 7,500)."
                          }
                        }]
                      }
                `}</script>
            </Helmet>

            <h1 style={{color: "#39414D"}}>
                Real estate agent commission calculator
            </h1>

            <p>Calculate net commission for real estate agents depending on the commission percent and the broker split percent</p>

            <SocialsShare 
                path={path}
            />

            <CommissionCalc />

            <div className="info-container">
                <hr></hr>
                <h2 className="info-header">An online calculator to calculate commission</h2>

                <section>
                    <h3 className="info-subheadder">What is a brokerage split in real estate?</h3>
                    <p className="info-paragraph">As an agent working under a brokerage, whatever commission that the agent gets from the sellers is split between the agent and the brokerage.  Typical broker commission splits are 50/50, 60/40, or 70/30.  For example,in a typical 70/30 split, the agent gets to take home 70% of the commission while the brokerage gets the remaining 30%.
                    </p>
                </section>
                <section>
                    <h3 className="info-subheadder">How to calculate a 70/30 commission split?</h3>
                    <p className="info-paragraph">
                    For example, lets say an agent sold a property valued at $250,000 and expects to receive a commission of 3%.<br></br><br></br>

                    This means that the total commission received in this transaction is $7,500 or (0.03 * 250,000).<br></br><br></br>

                    Now, lets say that the brokerage that the agent works for expects a 70/30 split of the commission.  This means that of the total $7,500 of commission, the agent gets to keep 70% and the brokerage gets the remaining %30.<br></br><br></br>

                    Based on this, the agent takes home $5,250 or (0.7 * 7,500) and the brokerage get $2,250 or (0.3 * 7,500).<br></br><br></br>

                    Using this example for reference, you can also easily calculate other commission splits.  For calculating 50/50 or 60/40 splits, simply take the total commission and multiply but the relative percents.  For example in a 50/50 split with the example above, the agent would take home $3,750 or (0.5*7,500) and the broker would also get $3,750 or (0.5 * 7,500).
                    </p>
                </section>
                    
            <RelatedContent />

            </div>
        </div>
    )
}
