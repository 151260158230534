import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import InfoCard from './InfoCard'
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { CardContent, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

export default class TwoPercentCalc extends Component {

    constructor(){
        super();
        this.state = {
            path: 'https://houseStatistics/blog/two-percent-rule',
            homeValue: 100000,
            
            rentCard: {
                unit: '',
                value: '',
                description: ''
            },
        }
    }

    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    componentDidMount() {
        this.calculateTwoPercent()
    }

    calculateTwoPercent() {
        function round(value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        }
        let twoPercent = round(0.02*this.state.homeValue, 2)
        this.setState({
            rentCard: {
                unit: '$',
                value: twoPercent,
                description: 'Monthly rent based on 2% rule'
            }
        })
    }

    render() {
        return (
            <div>
                <Grid container spacing={0}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Paper>
                            <Card>
                                <CardContent>
                                    <h1>2% rule calculator</h1>
                                    <FormControl fullWidth variant="outlined" size="small">
                                            <InputLabel htmlFor="home-value">Home Value</InputLabel>
                                            <OutlinedInput
                                                id="home-value"
                                                type="number"
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                labelWidth={90}
                                                defaultValue={this.state.homeValue}
                                                onChange={this.handleChange('homeValue')}                               
                                            />
                                    </FormControl>
                                    <Button fullWidth onClick={() => this.calculateTwoPercent()} variant="contained" color="secondary" style={{marginTop: '1em'}}>Calculate</Button>
                                </CardContent>
                            </Card>
                        </Paper>
                        <InfoCard 
                            unit={this.state.rentCard.unit}
                            value={this.state.rentCard.value}
                            description={this.state.rentCard.description}
                        />
                    </Grid>
                </Grid>
                
            </div>
        )
    }
}
