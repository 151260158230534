import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InfoCard from '../InfoCard'
import Info from '../Info'
import DonutChart from '../DonutChart'
import Card from '@material-ui/core/Card';
import { CardContent, Button } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import {Helmet} from "react-helmet";
import SocialsShare from '../SocialsShare';

export default class Roicalc extends Component {
    constructor(){
        super();
        this.state = {
            path: 'https://houseStatistics.com/roi-calculator',
            loanType: 15,
            homeValue: 100000,
            downpayment: 25,
            interestRate: 3.6,
            rent: 1000,
            ptax: 100,
            hoa: 0,
            homeInsurance: 100,
            renoClosing: 1000,
            
            roiCard: {
                unit: '',
                value: '',
                description: ''
            },
            downpaymentCard: {
                unit: '',
                value: '',
                description: ''
            },
            cashFlowCard: {
                unit: '',
                value: '',
                description: ''
            },
            pmtCard: {
                unit: '',
                value: '',
                description: ''
            },
            Donut: {
                series: [],
                labels: []
            }
        }
    }

    handleChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };

    componentDidMount() {
        this.calculateRoi()
    }
    
    calculateRoi() {
        function round(value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        }
        let downpayment = this.state.downpayment
        let interest = this.state.interestRate
        let ptax = this.state.ptax
        let renovations = this.state.renoClosing
        let hoa = this.state.hoa
        let homeInsurance = this.state.homeInsurance
        let rent = this.state.rent
        let homeValue = this.state.homeValue
        let loan = this.state.loanType

        let donutSeries = []

        let downpaymentPercent = downpayment / 100
        let interestRate = interest / 100
        let downpaymentValue = round(downpaymentPercent * homeValue, 2);
        let outOfPocketExpense = parseFloat(downpaymentValue) + parseFloat(renovations)
        let principal = homeValue - downpaymentValue;
        let PMT = round((principal*(interestRate / 12))/(1 - Math.pow((1 + (interestRate / 12)), (-12 * loan))),2)
        let waterTaxInsurance = parseFloat((ptax)) + parseFloat(homeInsurance) + parseFloat(hoa);
        let monthlyCashFlow = round(rent - PMT - waterTaxInsurance, 2)
        let annualReturn = round(monthlyCashFlow * 12, 2)
        let ROI = 100 * annualReturn / outOfPocketExpense
        let annualPtax = round(12*ptax ,2)
        let annualHoa = round(12*hoa, 2)
        let annualPmt = round(12*PMT, 2)
        let annualHomeInsurance = round(12*parseFloat(homeInsurance), 2)
        donutSeries = [round(parseFloat(downpaymentValue),0), round(parseFloat(renovations), 0), round(parseFloat(annualPmt),0), round(parseFloat(annualPtax),0), round(parseFloat(annualHoa),0), round(parseFloat(annualHomeInsurance), 0)]
        
        ROI = round(ROI, 2);
        
        this.setState({
            roiCard: {
                unit: '%',
                value: ROI,
                description: 'Return on Investment'
            },
            downpaymentCard: {
                unit: '$',
                value: downpaymentValue,
                description: 'Downpayment'
            },
            cashFlowCard: {
                unit: '$',
                value: monthlyCashFlow,
                description: 'Cash flow per month'
            },
            pmtCard: {
                unit: '$',
                value: PMT,
                description: 'Mortgage per month'
            },
            Donut: {
                series: donutSeries,
                labels: ['downpayment', 'renovations+closing', 'Mortgage', 'property tax', 'HOA', 'Home Insurance']
            }
        })
    }

    render() {
        
        return (
            <div style={{color: "#39414D"}}>
                <Helmet>
                    <title>Real Estate Investment ROI Calculator</title>
                    <meta name="title" content="Real Estate Investment ROI Calculator" />
                    <meta name="description" content="Calculate return on investment (ROI) and compare 15 year to 30 year mortgages for any Real Estate investment property using this free rental property analysis tool - useful for real estate agents and investors.  The purpose of this application is to simplify the process of calculating ROI for real estate investment properties without having to use excel." />
                    <script type="application/ld+json">{`
                        {
                            "@context": "https://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [{
                              "@type": "Question",
                              "name": "What is return on investment (ROI)?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "ROI is a measure of how profitable a particular investment may be.  ROI is calculated by dividing the potential returns of an investment by the cost of the investment itself.  The purpose of this application is to simplify the process of calculating ROI for real estate investment properties.  Having this information can help you make more informed decisions when entering into a particular investment"
                              }
                            }, {
                              "@type": "Question",
                              "name": "What is a good ROI?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The definition of a good ROI can be different from investor to investor. For reference, the S&P500 index has historically provided a 8-9 ROI yearly.  If 8-9 ROI is assumed to be the average ROI for an investment, anything larger can be considered a good investment."
                              }
                            }, {
                              "@type": "Question",
                              "name": "How to use a real estate ROI calculator?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Simply input key information about the property and the return on investment percent will be calculated.  This calculator takes into account several variables used to make a more accurate calculation.
                                                              <ul>
                                                                  <li><strong>Property value</strong></li>
                                                                  <li><strong>Downpayment percent</strong></li>
                                                                  <li><strong>Loan interest rate</strong></li>
                                                                  <li><strong>Rent per month</strong></li>
                                                                  <li><strong>Property tax</strong></li>
                                                                  <li><strong>HOA</strong></li>
                                                                  <li><strong>Home insurance</strong></li>
                                                                  <li><strong>Renovation + closing costs</strong></li>
                                                              </ul>"
                              }
                            }]
                          }
                    `}</script>
                </Helmet>
                <h1 style={{color: "#39414D"}}>
                Real Estate Investment ROI Calculator
                </h1>
                <p>Calculate return on investment (ROI) for any real estate investment property</p>
                <SocialsShare 
                    path={this.state.path}
                />
                <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={12}>
                        <Paper>
                            <Card style={{marginTop: '1em'}}>
                                <CardContent>
                                <h2>Property Details</h2>
                                <FormControl component="fieldset" size="small" style={{textAlign: 'left'}}>
                                    <FormLabel component="legend">Loan type</FormLabel>
                                    <RadioGroup aria-label="loan-type" name="loan-type" value={parseFloat(this.state.loanType)} onChange={this.handleChange('loanType')}>
                                        <FormControlLabel value={15} control={<Radio />} label="15 year fixed" />
                                        <FormControlLabel value={30} control={<Radio />} label="30 year fixed" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel htmlFor="home-value">Home Value</InputLabel>
                                    <OutlinedInput
                                        id="home-value"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        labelWidth={90}
                                        defaultValue={this.state.homeValue}
                                        onChange={this.handleChange('homeValue')}                                  
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" style={{marginTop: '1em'}} size="small">
                                    <InputLabel htmlFor="downpayment">Downpayment</InputLabel>
                                    <OutlinedInput
                                        id="downpayment"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                        labelWidth={105}
                                        defaultValue={this.state.downpayment}
                                        onChange={this.handleChange('downpayment')}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" style={{marginTop: '1em'}} size="small">
                                    <InputLabel htmlFor="interest-rate">Interest-Rate</InputLabel>
                                    <OutlinedInput
                                        id="interest-rate"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                        labelWidth={95}
                                        defaultValue={this.state.interestRate}
                                        onChange={this.handleChange('interestRate')}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" style={{marginTop: '1em'}} size="small">
                                    <InputLabel htmlFor="rent">Rent/month</InputLabel>
                                    <OutlinedInput
                                        id="rent"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        labelWidth={85}
                                        defaultValue={this.state.rent}
                                        onChange={this.handleChange('rent')}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" style={{marginTop: '1em'}} size="small">
                                    <InputLabel htmlFor="ptax">Property Tax/month</InputLabel>
                                    <OutlinedInput
                                        id="ptax"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        labelWidth={145}
                                        defaultValue={this.state.ptax}
                                        onChange={this.handleChange('ptax')}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" style={{marginTop: '1em'}} size="small">
                                    <InputLabel htmlFor="hoa">HOA/month</InputLabel>
                                    <OutlinedInput
                                        id="hoa"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        labelWidth={85}
                                        defaultValue={this.state.hoa}
                                        onChange={this.handleChange('hoa')}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" style={{marginTop: '1em'}} size="small">
                                    <InputLabel htmlFor="home-insurance">Home Insurance/month</InputLabel>
                                    <OutlinedInput
                                        id="home-insurance"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        labelWidth={170}
                                        defaultValue={this.state.homeInsurance}
                                        onChange={this.handleChange('homeInsurance')}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" style={{marginTop: '1em'}} size="small">
                                    <InputLabel htmlFor="reno">Reno+Closing Costs</InputLabel>
                                    <OutlinedInput
                                        id="reno"
                                        type="number"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        labelWidth={145}
                                        defaultValue={this.state.renoClosing}
                                        onChange={this.handleChange('renoClosing')}
                                    />
                                </FormControl>
                                <Button fullWidth onClick={() => this.calculateRoi()} variant="contained" color="secondary" style={{marginTop: '1em'}}>Calculate</Button>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12}>
                            <DonutChart 
                                options={{}}
                                series={this.state.Donut.series}
                                labels={this.state.Donut.labels}
                                title="First year expenses"
                                total="Total first year expenses"
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Paper>
                                    <InfoCard 
                                        unit={this.state.roiCard.unit}
                                        value={this.state.roiCard.value}
                                        description={this.state.roiCard.description}
                                    />
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper>
                                    <InfoCard 
                                        unit={this.state.downpaymentCard.unit}
                                        value={this.state.downpaymentCard.value}
                                        description={this.state.downpaymentCard.description}
                                    />
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper>
                                    <InfoCard 
                                        unit={this.state.pmtCard.unit}
                                        value={this.state.pmtCard.value}
                                        description={this.state.pmtCard.description}
                                    />
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper>
                                    <InfoCard 
                                        unit={this.state.cashFlowCard.unit}
                                        value={this.state.cashFlowCard.value}
                                        description={this.state.cashFlowCard.description}
                                    />
                                    </Paper>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
                <Info />
            </div>
        )
    }
}
